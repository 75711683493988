import { InputHTMLAttributes, useRef } from 'react'
import styled from '@emotion/styled'
import { margin, MarginProps } from 'styled-system'
import getSystemProps from 'shared/helpers/getSystemProps'
import ValidationError from 'library/molecules/ValidationError'
import { getCurrencySymbol } from 'shared/helpers/money'
import Icon, { IconProps } from 'shared/library/atoms/Icon'
import {
  useConfigureInput,
  UseConfigureInputParams
} from 'shared/state/useConfigureInput'
import { useField } from 'shared/providers/FormProvider/formProvider'

export interface InputCoreProps
  extends MarginProps,
    UseConfigureInputParams<HTMLInputElement>,
    Pick<
      InputHTMLAttributes<HTMLInputElement>,
      'type' | 'autoComplete' | 'lang' | 'min'
    > {
  name: string

  /**
   * Useful for number inputs
   */
  currency?: string
  /**
   * Takes a string to output a class. Related to emotion - It lets another component style it
   */
  className?: string
  /**
   * We are not using Formik's validation to determine this value because it is not possible to access
   * Formik's schema validation via the field. The only access the field has to the validation is to
   * receive the error after the field has been validated by user interaction.
   */
  isRequired?: boolean
  value?: unknown
  onFocus?: (e: any) => void
}

export interface InputLargeVariant {
  variant?: 'large'
  label?: never
  placeholder: string
  icon: IconProps['name'] | 'currency'
}

interface InputDefaultVariant {
  variant?: never
  label?: string
  placeholder?: string
  icon?: never
}

/**
 * Input has several variants, some of which do not use the same props.
 * We use `[key]?: never` to specify when a prop is not available for a particular variant.
 */
export type InputProps = InputCoreProps &
  (InputLargeVariant | InputDefaultVariant)

interface InputStyle
  extends Pick<
    InputProps,
    'isRequired' | 'currency' | 'variant' | 'type' | 'readOnly'
  > {
  hasValue: boolean
  hasError?: boolean
  inputHeight: number
}

const INPUT_LARGE = {
  iconSize: 16,
  paddingLeft: 5
}

/**
 * Input is used in place of a traditional `input` element. This has
 * Formik baked into it for ease of use.
 *
 * It uses styled-system to add margins if necessary, e.g. a section of
 * two inputs in the same row and the second input needs margin-let.
 */

const Input = (props: InputProps) => {
  const {
    label,
    name,
    type = 'text',
    currency,
    className,
    isRequired,
    variant,
    icon,
    disabled,
    readOnly,
    externallyControlled,
    onChange,
    onBlur,
    onFocus,
    onSubmitKeysDown,
    ...other
  } = props
  const { field, meta } = useField<string | number | undefined>(name)
  const inputRef = useRef<HTMLInputElement>(null)

  const {
    hasValue,
    hasError,
    isReadOnly,
    handleOnChange,
    handleOnBlur,
    handleOnFocus,
    handleOnKeyDown
  } = useConfigureInput({
    name,
    disabled,
    readOnly,
    externallyControlled,
    onChange,
    onBlur,
    onFocus,
    onSubmitKeysDown
  })

  const STYLE_PROPS: InputStyle = {
    hasValue,
    hasError,
    isRequired,
    currency,
    variant,
    inputHeight: variant ? 76 : 48
  }

  const focusInput = () => inputRef.current?.focus()

  return (
    <StyledDiv {...getSystemProps(props)} className={className}>
      <StyledWrapper onClick={focusInput} onFocus={focusInput} {...STYLE_PROPS}>
        {label && (
          <StyledLabel htmlFor={name} {...STYLE_PROPS}>
            {label}
          </StyledLabel>
        )}
        {/**
         * The currency icon is displayed with a pseudo element.
         */}
        {icon && (!currency || icon !== 'currency') && (
          <StyledIcon
            name={icon}
            size={INPUT_LARGE.iconSize}
            color="currentColor"
          />
        )}
        <StyledInput
          id={name}
          {...field}
          type={currency ? 'number' : type}
          disabled={disabled}
          aria-invalid={!!meta.error}
          readOnly={isReadOnly}
          onChange={handleOnChange}
          onBlur={handleOnBlur}
          onFocus={handleOnFocus}
          onKeyDown={handleOnKeyDown}
          ref={inputRef}
          {...STYLE_PROPS}
          {...other}
          value={field.value}
        />
      </StyledWrapper>
      {/* Display errors if validation fails */}
      {hasError ? <ValidationError>{meta.error}</ValidationError> : null}
    </StyledDiv>
  )
}

export default Input

const StyledDiv = styled.div(
  () => ({
    flex: '1 0 0'
  }),
  margin
)

const StyledWrapper = styled.div<InputStyle>(
  ({ currency }) => ({
    width: '100%',
    position: 'relative',
    // Currency icon
    '&::before': {
      display: 'none',
      content: `"${getCurrencySymbol(currency)}"`,
      position: 'absolute',
      color: 'inherit'
    }
  }),
  ({ theme, hasError }) =>
    hasError && {
      color: theme.colors.state.error
    },
  ({ theme, variant, inputHeight, hasValue, currency }) => {
    // Using switch to facilitate the division between the styles
    switch (variant) {
      // Displays the label as placeholder
      case 'large':
        return {
          color: theme.colors.text.primary,
          '&:hover': {
            color: theme.colors.text.primary
          },
          ...(currency && {
            '&::before': {
              display: 'block',
              top: '50%',
              left: theme.space[INPUT_LARGE.paddingLeft],
              transform: 'translateY(-50%)'
            }
          })
        }

      // Displays label above the input
      default:
        return {
          ...(hasValue &&
            currency && {
              '&::before': {
                display: 'block',
                left: 10,
                bottom: inputHeight / 2 - 2 + 'px' // fixed top to prevent the button moving if the input needs to show some error
              }
            })
        }
    }
  }
)

const StyledLabel = styled.label<InputStyle>(
  ({ theme }) => ({
    ...theme.fontStyle.h6,
    userSelect: 'none',
    color: 'inherit',
    marginBottom: theme.space[2],
    display: 'block',
    [`${StyledDiv} + ${StyledDiv} &`]: {
      marginTop: theme.space[4]
    }
  }),
  ({ theme, isRequired }) =>
    isRequired && {
      '&::after': {
        content: "'*'",
        color: theme.colors.state.error,
        marginLeft: theme.space[1]
      }
    }
)

const StyledInput = styled.input<InputStyle>(
  ({ theme, hasError }) => ({
    border: theme.border,
    boxSizing: 'border-box',
    outline: 'none',
    color: 'inherit',
    width: '100%',
    transition: `${theme.transitionTime[1]}s ease`,
    '&::placeholder': {
      transition: `color ${theme.transitionTime[1]}s ease`,
      color: theme.colors.text.placeholder
    },
    '&:focus-visible': {
      border: `2px solid ${theme.colors.border.secondary}`,
      transition: `${theme.transitionTime[1]}s ease`,
      '&:hover': {
        border: `2px solid ${theme.colors.border.secondary}`,
        transition: `${theme.transitionTime[1]}s ease`
      }
    },
    '&:hover': {
      border: `1px solid ${theme.colors.border.secondary}`
    },
    '&[aria-invalid="true"]': {
      borderColor: theme.colors.border.primary
    },
    ...(hasError && {
      borderColor: 'inherit'
    })
  }),
  ({ theme, variant, currency, inputHeight, hasValue }) => {
    // Using switch to facilitate the division between the styles
    switch (variant) {
      // Displays the label as placeholder
      case 'large':
        return {
          ...theme.fontStyle.h6,
          height: inputHeight,
          paddingLeft:
            // Using combination of [space + icon + space] to add right and left space around the icon
            theme.space[INPUT_LARGE.paddingLeft] +
            INPUT_LARGE.iconSize +
            theme.space[INPUT_LARGE.paddingLeft] / 2,
          paddingRight: theme.space[4],
          boxShadow: theme.shadows.black[1],
          borderRadius: theme.radii[3],
          ...(currency && {
            paddingLeft:
              theme.space[INPUT_LARGE.paddingLeft] + INPUT_LARGE.iconSize
          }),
          ...(hasValue && {
            '&::placeholder': {
              color: 'currentcolor'
            }
          }),
          '&:hover': {
            '&::placeholder': {
              color: theme.colors.text.primary
            }
          }
        }

      // Displays label above the input
      default:
        return {
          ...theme.fontStyle.p2,
          height: inputHeight,
          width: '100%',
          paddingLeft: theme.space[4],
          paddingRight: theme.space[4],
          marginTop: theme.space[2],
          marginBottom: theme.space[2],
          borderRadius: theme.radii[1],
          '&:hover': {
            '&::placeholder': {
              color: theme.colors.text.secondary
            }
          },
          ...(currency && {
            paddingLeft: theme.space[5]
          })
        }
    }
  }
)

const StyledIcon = styled(Icon)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: theme.space[INPUT_LARGE.paddingLeft],
  transform: 'translateY(-50%)'
}))
