import styled from '@emotion/styled'
import { margin, MarginProps } from 'styled-system'
import getSystemProps from 'shared/helpers/getSystemProps'
import ValidationError from 'library/molecules/ValidationError'
import PhoneInput, { Country, Value } from 'react-phone-number-input'
import arrow from 'assets/icons/individualIconsForSprite/general/arrow-left.svg'
import { rgba } from 'polished'
import {
  useConfigureInput,
  UseConfigureInputParams
} from 'shared/state/useConfigureInput'
import { useField } from 'shared/providers/FormProvider/formProvider'
import createSyntheticChangeEvent from 'shared/helpers/createSyntheticChangeEvent'

/**
 * react-phone-number-input uses the E.164 format for the value.
 * E.164 defines a general format for international telephone numbers.
 *
 * The value can be saved in a database and later be output on a page using formatPhoneNumberIntl() function.
 * import PhoneInput, { formatPhoneNumberIntl } from 'react-phone-number-input'
 */
export type InputTelephoneValue = Value

export interface InputTelephoneProps
  extends MarginProps,
    /**
     * Not using the default handleOnChange because react-phone-number-input has a custom onChange event.
     */
    Omit<UseConfigureInputParams<HTMLElement>, 'onChange'> {
  label?: string
  placeholder?: string
  name: string
  /**
   * Useful for number inputs
   */
  currency?: string
  /**
   * Takes a string to output a class. Related to emotion - It lets another component style it
   */
  className?: string
  onChange?: (value?: InputTelephoneValue) => void
  /**
   * We are not using Formik's validation to determine this value because it is not possible to access
   * Formik's schema validation via the field. The only access the field has to the validation is to
   * receive the error after the field has been validated by user interaction.
   */
  isRequired?: boolean
  /**
   * Used for determining the telephone code and country's flag e.g. "GB", "US", etc.
   */
  defaultCountry?: string | null
}

type InputStyle = Pick<InputTelephoneProps, 'isRequired'>

/**
 * InputTelephone is used for telephone inputs only.
 *
 * This has Formik baked into it for ease of use.
 *
 * It uses styled-system to add margins if necessary, e.g. a section of
 * two inputs in the same row and the second input needs margin-let.
 */
export const InputTelephone = (props: InputTelephoneProps) => {
  const {
    label,
    name,
    defaultCountry,
    className,
    onChange,
    placeholder,
    isRequired,
    disabled,
    readOnly,
    externallyControlled,
    onBlur,
    onFocus,
    onSubmitKeysDown,
    ...other
  } = props
  const { field, meta } = useField<InputTelephoneValue>(name)
  const { hasError, isReadOnly, handleOnBlur, handleOnFocus, handleOnKeyDown } =
    useConfigureInput({
      name,
      disabled,
      readOnly,
      externallyControlled,
      onBlur,
      onFocus,
      onSubmitKeysDown
    })

  const STYLE_PROPS: InputStyle = {
    isRequired
  }

  /**
   * We have some phone numbers in the DB without a country code prefix which is undesirable going forwards due to our services
   * becoming more international
   * So if a user has signed in we might see this incorrect format surface via an initial value
   * We want to surface this error immediately - otherwise the user will not know it has passed validation until they have touched the input and
   * they may think the form is broken
   */
  const errored = hasError || (meta.initialValue && meta.error)

  return (
    <StyledDiv
      {...getSystemProps(props)}
      className={className}
      {...STYLE_PROPS}
    >
      {label && (
        <StyledLabel htmlFor={name} {...STYLE_PROPS}>
          {label}
        </StyledLabel>
      )}
      <PhoneInput
        id={name}
        {...field}
        placeholder={placeholder}
        initialValueFormat="national"
        defaultCountry={defaultCountry as Country}
        disabled={disabled}
        aria-invalid={!!meta.error}
        autoComplete="tel"
        readOnly={isReadOnly}
        onChange={(value?: InputTelephoneValue) => {
          const telephoneNumber = value ?? ('' as InputTelephoneValue)
          if (onChange) onChange(telephoneNumber)
          const syntheticEvent = createSyntheticChangeEvent({
            name,
            value
          })
          field.onChange(syntheticEvent)
        }}
        onBlur={handleOnBlur}
        onFocus={handleOnFocus}
        onKeyDown={handleOnKeyDown}
        {...other}
      />
      {/* Display errors if validation fails */}
      {errored ? <ValidationError>{meta.error}</ValidationError> : null}
    </StyledDiv>
  )
}

const StyledDiv = styled.div<InputStyle>(
  () => ({
    display: 'flex',
    flexDirection: 'column' as const,
    flex: '1 0 0'
  }),
  /**
   * PhoneInput
   *
   * The PhoneInput is styled inside StyledDiv instead of using styled(PhoneInput) because some features stop working
   * such as "defaultCountry" when PhoneInput is used as styled(PhoneInput).
   */
  ({ theme }) => {
    const vars = {
      colorFocus: theme.colors.primary,
      arrowWidth: '12px',
      flagWidth: '25px',
      flagBorderColor: theme.colors.border.primary
    }

    return {
      flex: '1 0 0',

      '.PhoneInput': {
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.space[2],
        marginBottom: theme.space[2],
        border: theme.border,
        borderRadius: theme.radii[1],
        '&:hover': {
          borderColor: theme.colors.border.secondary
        }
      },
      '.PhoneInputInput': {
        flex: 1,
        minWidth: 0,
        zIndex: 2,
        border: 'none',
        color: 'inherit',
        height: 48,
        '&::placeholder': {
          color: theme.colors.text.placeholder
        }
      },
      '.PhoneInputCountryIcon': {
        width: vars.flagWidth + theme.space[4],
        height: `calc(${vars.flagWidth} / 1.5)`,
        paddingLeft: theme.space[4],
        flexShrink: 0
      },
      '.PhoneInputCountryIcon--square': {
        width: vars.flagWidth
      },
      '.PhoneInputCountryIcon-Border': {
        backgroundColor: rgba(theme.colors.primary, 0.1),
        /**
         * Border is added via `boxShadow` because `border` interferes with `width`/`height`.
         * For some reason the `<img/>` is not stretched to 100% width and height
         * and sometime there can be seen white pixels of the background at top and bottom,
         * so an additional "inset" border is added.
         * */
        boxShadow: `0 0 0 1px ${vars.flagBorderColor}, inset 0 0 0 1px ${vars.flagBorderColor}`
      },
      '.PhoneInputCountryIconImg': {
        display: 'block',
        /**
         * 3rd party <SVG/> flag icons won't stretch if they have `width` and `height`.
         * Also, if an <SVG/> icon's aspect ratio was different, it wouldn't fit too.
         * */
        width: '100%',
        maxWidth: vars.flagWidth,
        height: '100%'
      },

      /**
       *
       * Styling native country `<select/>`.
       *
       * */
      '.PhoneInputCountry': {
        position: 'relative',
        alignSelf: 'stretch',
        display: 'flex',
        alignItems: 'center',
        marginRight: theme.space[3]
      },
      '.PhoneInputCountrySelect': {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: 1,
        border: 0,
        opacity: 0,
        cursor: 'pointer'
      },
      '.PhoneInputCountrySelect[disabled], .PhoneInputCountrySelect[readonly]':
        {
          cursor: 'default'
        },
      '.PhoneInputCountrySelectArrow': {
        content: '""',
        display: 'block',
        height: 18,
        width: 18,
        background: theme.colors.icon.grey,
        transition: `transform ${theme.transitionTime}s ease`,
        /**
         * Importing a single SVG file instead of the sprite file because the sprite didn't
         * work on `background-image`. This exception is acceptable for this library
         * because we need to overwrite their style.
         */
        mask: `url(${arrow}) center / 20px no-repeat`,
        transform: 'rotate(-90deg)',
        marginLeft: theme.space[3],
        flexShrink: 0
      },
      '.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon + .PhoneInputCountrySelectArrow':
        {
          opacity: 1,
          color: vars.colorFocus
        },
      '.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon-Border': {
        boxShadow: `0 0 0 1px ${vars.colorFocus}, inset 0 0 0 1px ${vars.colorFocus}`
      },
      '.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon .PhoneInputInternationalIconGlobe':
        {
          opacity: 1,
          color: vars.colorFocus
        }
    }
  },
  margin
)

const StyledLabel = styled.label<InputStyle>(
  ({ theme }) => ({
    ...theme.fontStyle.h6,
    userSelect: 'none',
    color: 'inherit',
    marginBottom: theme.space[2],
    display: 'block',
    [`${StyledDiv} + ${StyledDiv} &`]: {
      marginTop: theme.space[4]
    }
  }),
  ({ theme, isRequired }) =>
    isRequired && {
      '&::after': {
        content: "'*'",
        color: theme.colors.state.error,
        marginLeft: theme.space[1]
      }
    }
)
